<template>
	<div class="root">
		<router-view />
		<menuBar></menuBar>
		<Footer></Footer>
	</div>
</template>

<script>
import menuBar from '@/components/menuBar/menuBar.vue';
import Footer from '@/components/Footer/Footer.vue';
export default {
	name: 'index',
	components: {
		menuBar,
		Footer,
	},
	created() {
		window.document.documentElement.setAttribute("data-size","middle")
	},
};
</script>

<style lang="scss" scoped>
	.root{
		// padding-top: 164px;
	}
</style>

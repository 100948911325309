<template>
	<!-- 菜单 -->
	<div class="quick_menu_box">
		<div class="quick_one">
			<div class="text">快速</div>
			<div class="text">导航</div>
		</div>
		<div class="hblock12"></div>
		<div class="quick_two">
			<div class="list_item" v-for="(item,index) in menulist" :key="index" @click="menuClick(index,item.url)">
				<span class="text">{{item.menu}}</span>
			</div>
		</div>
		<div class="hblock12"></div>
		<div class="yx_top_box" @click="hanldetoTop" v-if="gotop">
			<img style="width: 12px;height: 12px;" src="@/assets/static/top_icon.png" />
			<div class="yx_service_float">
				<span class="yx_service_triangle"></span>
				<span>返回顶部</span>
			</div>
		</div>
		<div class="yx_top_box_empty" v-if="!gotop"></div>
	</div>
</template>
<script>
import {mapState} from 'vuex'
export default {
	name: 'menuBar',
	data() {
		return {
			gotop: false,
			menulist: [
				{
					menu: '首页',
					url: 'home'
				},
				{
					menu: '我的',
					url: 'mine'
				},
				{
					menu: '收藏',
					url: 'collection'
				},
				{
					menu: '足迹',
					url: 'footprint'
				},
				{
					menu: '优惠券',
					url: 'coupon'
				},
			],
		};
	},
	computed: {
		...mapState(['userInfo', 'isLogin','curpage']),
	},
	created() {
		// 此处true需要加上，不加滚动事件可能绑定不成功
		window.addEventListener("scroll", this.handleScroll, true);
		console.log(this.curpage)
	},
	methods: {
		handleScroll() {
		    var scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
		    scrolltop > 500 ? (this.gotop = true) : (this.gotop = false);
		},
		hanldetoTop(){
			var top = document.documentElement.scrollTop || document.body.scrollTop;
			// 实现滚动效果
			const timeTop = setInterval(() => {
			  document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
			  if (top <= 0) {
			    clearInterval(timeTop);
			  }
			}, 10);
		},
		menuClick(index, url) {
			if(url=="coupon"){
				//优惠券
				if(this.curpage=="mine"){
					this.$router.push({ path: '/BlankPage', query: {redirect: "coupon",pathto:"mine"}})
				}else{
					this.$router.push({ path: '/mine', query: {redirect: "coupon"}})
				}
				this.$store.commit('SET_CURPAGE', 'mine')
			}else if(url=="collection"){
				if(this.curpage=="mine"){
					this.$router.push({ path: '/BlankPage', query: {redirect: "collection",pathto:"mine"}})
				}else{
					this.$router.push({ path: '/mine', query: {redirect: "collection"}})
				}
				this.$store.commit('SET_CURPAGE', 'mine')
			}else if(url=="footprint"){
				if(this.curpage=="mine"){
					this.$router.push({ path: '/BlankPage', query: {redirect: "footprint",pathto:"mine"}})
				}else{
					this.$router.push({ path: '/mine', query: {redirect: "footprint"}})
				}
				this.$store.commit('SET_CURPAGE', 'mine')
			}else if(url){
				//this.$emit("menuClick",url)
				this.$store.commit('SET_CURPAGE', url)
				this.$router.push({
					name: url
				});
			}else{
				this.$message.info("此模块暂未开放")
			}
		},
	}
};
</script>
<style lang="scss" scoped>
.quick_menu_box{
	width: 56px;
	position: fixed;
	right: 30px;
	bottom: 188px;
	z-index: 8888;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	.quick_one{
		width: 54px;
		height: 54px;
		background: linear-gradient(180deg, #1370FB 0%, #13C3FB 100%);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.text{
			font-size: 12px;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 15px;
			letter-spacing: 1px;
		}
	}
	.hblock12{
		width: 100%;
		height: 12px;
	}
	.quick_two{
		width: 52px;
		padding: 23px 0;
		background: #FFFFFF;
		box-shadow: 0px 1px 9px 0px rgba(25,31,40,0.09);
		border-radius: 38px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		position: relative;
		.list_item{
			padding: 12px 5px;
			border-bottom: 1px solid #F5F5F5;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			.text{
				font-size: 12px;
				font-weight: 400;
				color: #999999;
			}
		}
	}
	.yx_top_box{
		width: 52px;
		height: 52px;
		background: #FFFFFF;
		box-shadow: 0px 1px 9px 0px rgba(25,31,40,0.09);
		border-radius: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: relative;
		.yx_service_float{
			display: none;
			position: absolute;
			right: 72px;
			height: 40px;
			line-height: 40px;
			padding: 0 14px;
			background: #fff;
			border-radius: 5px;
			text-align: center;
			border: 1px solid #e1e6ec;
			white-space: nowrap;
			box-sizing: border-box;
			box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
			font-weight: 400;
			font-size: 13px;
			color: #333333;
		}
		@keyframes fade-in{0%{opacity:.4;right:92px}100%{opacity:1;right:72px}}
		&:hover .yx_service_float{
			display: block;
			opacity: 1;
			transition: linear .2s;
			animation-name: fade-in;
			animation-duration: .3s;
			animation-iteration-count: 1;
			animation-delay: 0s;
		}
		.yx_service_triangle{
			top: 12px;
			right: -11px;
			position: absolute;
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			border-left: 11px solid #e1e6ec;
			z-index: 1010;
		}
		.yx_service_triangle:after{
			top: -6px;
			right: 1px;
			position: absolute;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-left: 10px solid #fff;
			z-index: 1000;
		}
	}
	.yx_top_box_empty{
		width: 52px;
		height: 52px;
	}

}
</style>

<template>
	<!-- 底部 -->
    <div class="index_footer">
    	<div class="foot_con1">
			<div class="w1240 yxcompany">
				<div class="leftbox fontsize14">
					<div class="text1 fontweight">关于我们</div>
					<div class="text1">平台简介</div>
					<div class="text1">联系我们</div>
				</div>
				<div class="rightbox fontsize14">
					<div class="itemobj">
						<img class="img" src="../../assets/static/logo.png"/>
						<div class="text">公众号</div>
					</div>
					<div class="itemobj">
						<img class="img" src="../../assets/static/qrcode_web.png"/>
						<div class="text">微信小程序</div>
					</div>
				</div>
			</div>
    	</div>
		<div class="yx_line w1240"></div>
		<div class="foot_con2 fontsize14">
			<div class="w1240 yxinfo">
				<div class="text1"></div>
				<div class="text3">
					<a class="atext" :href="require('../../assets/static/yyzz.png')">营业执照</a>
					<a class="atext" :href="require('../../assets/static/certificate.png')">互联网药品信息服务资格证</a>
					<a class="atext" :href="require('../../assets/static/ypjyxkz.png')">药品经营许可证</a>
					<a class="atext" :href="require('../../assets/static/ylqxjyxkz.png')">医疗器械经营许可证</a>
				</div>
			</div>
			<div class="w1240 yxinfo" @click="See('http://beian.miit.gov.cn')">
				<div class="text1">网站备案/许可证号：</div>
				<div class="text2" v-html="'粤ICP备2022061642号&emsp;©2022-'+dateyear+'&emsp;广东星辰医药有限公司版权所有并保留所有权利'"></div>
			</div>
			<div class="w1240 yxinfo">
				<div class="text1">友情链接：</div>
				<div class="text2" v-html="'国家卫生和计划生育委员会&emsp;国家食品药品监督管理总局'"></div>
			</div>
		</div>
    </div>
</template>
<script>
export default {
	name: 'Footer',
	data() {
		return {
			current: 0, // 当前索引
			dateyear: ''
		};
	},
	created() {
		this.dateyear = new Date().getFullYear();
	},
	methods: {
		See(e) {
			this.$util.windowOpen(e)
		}
	}
};
</script>
<style type="text/css" scoped>

</style>
